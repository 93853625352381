<template>
  <div class="downloadApp">
    <Download class="hiden" />
    <DownloadPhone class="showBlock" />
  </div>
</template>
<script>
import Download from "../components/Download.vue";
import DownloadPhone from "../components/DownloadPhone.vue";
export default {
  name: "DownloadApp",
  components: { Download, DownloadPhone },
  mounted() {
    $(".mainCont").css({ borderBottom: "1px solid #EFF3FF" });
  },
  beforeRouteLeave() {
    $(".mainCont").css({ borderBottom: "none" });
  },
};
</script>
<style lang="scss" scoped>
.downloadApp {
  padding-top: 60px;
}
@media screen and (max-width: 600px) {
  .downloadApp {
    padding-top: 0px;
  }
}
</style>
